import React from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown from '@common/components/dropdown';
import { PredicateSelectorDate } from '@common/components/predicates-filter/filter-date';
import { PredicateSelectorMultiSelect } from '@common/components/predicates-filter/filter-multi-select';

import { EPredicateOperators } from '@common/definitions';
import { ScreenQuestionItem } from '@modules/learning/types/objects';
import { AnswerPredicate, Predicate } from '@common/types/objects';
import { PredicateSelectorNumber } from '@common/components/predicates-filter/filter-number';

const DEFAULT_PREDICATE: Predicate = {
  attribute: 'answers.value',
  comparison: EPredicateOperators.IN,
  value: [],
};

const DEFAULT_DATETIME_PREDICATE: Predicate = {
  attribute: 'answers.value',
  comparison: EPredicateOperators.BETWEEN,
  value: [null, null],
};

const DEFAULT_NUMBER_PREDICATE: Predicate = {
  attribute: 'answers.value',
  // @ts-expect-error
  comparison: null,
  value: 0
};

type SelectorProps = {
  answer: null | {
    attribute: 'answers.value';
    comparison: EPredicateOperators;
    value: unknown;
  };
  onChange: (newValue: unknown) => void;
};

const getSelectorByQuestion = (
  question?: ScreenQuestionItem
): (props: SelectorProps) => React.ReactNode => {
  // Some of the selectors need a default value, because the predicate
  // won't be set until the selector changes the value
  switch (question?.type) {
    case 'question_boolean':
      return ({ answer, onChange }) => {
        const onCheck = (value: boolean) => onChange({
          comparison: EPredicateOperators.EQ,
          value,
        });

        return (
          <>
            <Dropdown.CheckboxItem
              label={question.parameters.label_true}
              value={answer?.value === true}
              onChange={() => onCheck(true)}
            />
            <Dropdown.CheckboxItem
              label={question.parameters.label_false}
              value={answer?.value === false}
              onChange={() => onCheck(false)}
            />
          </>
        );
      };
    case 'question_datetime':
      return ({ answer, onChange }) => (
        <PredicateSelectorDate
          predicate={answer || DEFAULT_DATETIME_PREDICATE}
          onChange={onChange}
        />
      );
    case 'question_checklist':
    case 'question_dropdown':
    case 'question_multiple_choice':
      return ({ answer, onChange }) => (
        <PredicateSelectorMultiSelect
          labelKey="value"
          valueKey="id"
          predicate={answer || DEFAULT_PREDICATE}
          // @ts-expect-error
          options={question.parameters.options}
          onChange={onChange}
        />
      );
    case 'question_number':
    case 'question_slider':
      return ({ answer, onChange }) => (
        <PredicateSelectorNumber
          predicate={answer || DEFAULT_NUMBER_PREDICATE}
          onChange={onChange}
        />
      );
    default:
      return () => null;
  }
};

type QuestionSelectorProps = {
  predicate: { id: string, answer: AnswerPredicate | null };
  options: ScreenQuestionItem[];
  onChange: (newValue: unknown) => void;
};

const QuestionSelector = ({
  predicate,
  options = [],
  onChange,
}: QuestionSelectorProps) => {
  const { t } = useTranslation();

  const selectedQuestion = options.find((option) => option.id === predicate.id);
  const Selector = getSelectorByQuestion(selectedQuestion);

  return (
    <>
      <div className="dropdown-input">
        <select
          value={predicate.id}
          onChange={(e) => onChange({ id: e.target.value, answer: null })}
        >
          <option value="">{`-- ${t('common:predicates_filter_select')} --`}</option>
          {options.map(({ id, parameters }) => (
            <option key={id} value={id}>{parameters.text}</option>
          ))}
        </select>
      </div>
      {/* @ts-expect-error */}
      <Selector
        answer={predicate.answer}
        onChange={(newAnswer: any) => onChange({
          ...predicate,
          answer: {
            attribute: 'answers.value',
            ...predicate.answer,
            ...newAnswer,
          },
        })}
      />
    </>
  );
};

export default QuestionSelector;
