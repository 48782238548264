import SelectToggle from '@common/components/select-toggle';
import { AlertService } from '@common/services/alert';
import { Api } from '@common/services/api';
import { StoreState } from '@common/types/store';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type Props = {
  setEnabled: (enabled: boolean) => void,
  formId: string,
  organisationId: string | null,
  value: boolean
};

const LoggedUserNotificationsSelect = memo(({
  setEnabled, formId, organisationId, value
}: Props) => {
  const { t } = useTranslation();
  const onChange = useCallback(async (enabled: boolean) => {
    try {
      setEnabled(enabled);
      const baseUrl = `/v1/organisations/${organisationId}/forms/${formId}`;
      await Api.put(`${baseUrl}/moderators/me/notification_setting`, {
        enabledNotifications: enabled
      });
      AlertService.success(t('forms:notifications_settings_changed'));
    } catch (error: any) {
      console.log('error', error);
      setEnabled(!enabled);
      AlertService.warning(t('forms:could_not_update_notifications_settings'));
    }
  }, [setEnabled, organisationId, t, formId]);

  return (
    <SelectToggle
      value={value}
      label={t('forms:notifications')}
      textOn={t('forms:on')}
      textOff={t('forms:off')}
      iconOn={t('notifications')}
      iconOff={t('notifications_off')}
      onChange={onChange}
    />
  );
});

export function useLoggedUserModerator(selectedForm: any) {
  const loggedUser = useSelector((state: StoreState) => state.loggedUser);
  const userId = loggedUser?.user?.id;
  return useMemo(() => {
    return selectedForm?.settings?.moderators?.find((mod: any) => {
      return mod?.user_id === userId;
    });
  }, [selectedForm, userId]);
}
export default LoggedUserNotificationsSelect;
