import React, { memo } from 'react';
import { useComments } from '../network-forms/submission-comments/utils';
import PostComment from '../network-forms/submission-comments/post-comment';
import SubmissionCommentsList
  from '../network-forms/submission-comments/submission-comments-list';

type SubmissionCommentsProps = {
  organisationId: string;
  submissionId: string;
};

const SubmissionComments = memo(({
  organisationId,
  submissionId
}: SubmissionCommentsProps) => {
  const {
    comments,
    getComments,
    addComment,
    onDelete,
    submissionConfig
  } = useComments(organisationId, submissionId);

  return (
    <div className="comments">
      <SubmissionCommentsList
        comments={comments}
        getComments={getComments}
        onDelete={onDelete}
        submissionConfig={submissionConfig}
      />
      <hr />
      <PostComment
        organisationId={organisationId}
        submissionId={submissionId}
        addComment={addComment}
      />
    </div>
  );
});

export default SubmissionComments;
