import React from 'react';

require('./form-icon.scss');

type Props = {
  form: Record<string, any> & Partial<{
    color: string;
    icon: string;
  }>;
};

export const FormIcon = ({ form }: Props) => {
  if (!form?.icon) return null;

  return (
    <div className="FormIcon" style={{ backgroundColor: form.color }}>
      <img
        src={form.icon.startsWith('https://')
          ? form.icon
          : `https://assets.oneteam.io/globals/attachments/${form.icon}`}
        alt="Icon"
      />
    </div>
  );
};
