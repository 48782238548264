import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Overview, OverviewContent, OverviewHeader } from '@common/components/overview';
import { Bar } from '@common/components/bar';
import { Container } from '@common/components/container';
import { AsyncTable } from '@common/components/table';
import { EPredicateFilters, optionsPreviewer } from '@common/components/predicates-filter';
import Placeholder from '@common/components/placeholder';
import ImageItem from '@common/components/image-item/large';
import { EPredicateFields, EPredicateOperators } from '@common/definitions';
import { useTranslation } from 'react-i18next';
import { EQuestionTypes } from '@modules/survey/definitions';
import Submission from '@modules/forms/components/submissions/submission';
import moment from 'moment';
import QuestionSelector from './question-selector';
import QuestionPreviewer from './question-previewer';
import { SubmissionStatus } from '../submission-status';

import FiltersToolbar from '@common/components/filters-toolbar/filters-toolbar';
import FileExportDropdown from '@common/components/dropdown/file-export-dropdown';
import LoggedUserNotificationsSelect, { useLoggedUserModerator } from './logged-user-notifications-select';
import { useLocation } from 'react-router';
import { PredicateSelectorMultiSelect } from '@common/components/predicates-filter/filter-multi-select';

import '../../../survey/containers/responses/responses.scss';

// All question types except the content one (isn't really a question)
const allowedQuestionTypesToFilter = [
  EQuestionTypes.BOOLEAN,
  EQuestionTypes.DATE_TIME,
  EQuestionTypes.DROPDOWN,
  EQuestionTypes.MULTIPLE_CHOICE,
  EQuestionTypes.NUMBER,
  EQuestionTypes.SLIDER,
  EQuestionTypes.CHECKLIST
];

const Submissions = (props: any) => {
  // console.log('debug Submissions props', props);
  const {
    history,
    match,
    selectedForm,
    submissions,
    organisation,
    fetchSubmissions,
    downloadSubmissions,
    submission,
    submissionMeta,
    fetchSubmission,
    updateSubmissionStatus,
    deleteSubmission,
    submissionsPath,
    setLoggedUserNotificationsEnabled,
    ...data
  } = props;
  const { params } = match;
  const { t } = useTranslation();
  const [search, setSearch] = useState<string | null>();

  const questionsForFilter = useMemo(() => {
    return selectedForm?.screens
      .map(({ components }: any) => components[components.length - 1])
      // Exclude questions such as open text because they're difficult to search
      .filter((q: any) => !!q && allowedQuestionTypesToFilter.includes(q.type))
      // Exclude date time questions which only allow time input, we don't filter those
      .filter((q: any) => {
        return !(q.type === EQuestionTypes.DATE_TIME && !q.parameters.allow_date_select);
      });
  }, [selectedForm]);

  // console.log("debug questionsForFilter", questionsForFilter);
  // console.log("debug selectedForm", selectedForm);

  // beware! predicates hold configuration and state
  const [predicates, setPredicates] = useSubmissionsFilters(
    data,
    selectedForm,
    questionsForFilter
  );

  const { id } = params;
  const basePath = submissionsPath || `/admin/forms/${id}/edit/submissions`;
  const locationSearch = useLocation().search;
  useEffect(() => {
    let path = basePath;
    if (submissions.length > 0) {
      path += (`/${submissions[0].id}`);
    }
    history.replace(path + locationSearch);
  }, [submissions, basePath, locationSearch]);

  const dateFilter = predicates.find((p: any) => p.value.attribute === EPredicateFields.DATE);
  const networkFilter = predicates.find((p: any) => p.value.attribute === EPredicateFields.NETWORK);
  const functionFilter = predicates.find((p: any) => p.value.attribute === EPredicateFields.FUNCTION);
  const statusPredicate = predicates.find((p: any) => p.key === 'status');
  const questionPredicate = predicates.find((p: any) => p.key === 'question');
  const [startDate, endDate] = (dateFilter?.value?.value || []);

  const handleDownload = useCallback(async (extension: 'csv' | 'xlsx') => {
    if (!selectedForm) return;

    const date = moment().format('DD-MM-YYYY');
    await downloadSubmissions(
      selectedForm.id,
      `${selectedForm.title} - ${t('forms:tab_submissions')} ${date}`,
      extension
    );
  }, [selectedForm]);
  const downloadCSV = useCallback(() => handleDownload('csv'), [handleDownload]);
  const downloadXLSX = useCallback(() => handleDownload('xlsx'), [handleDownload]);

  const showStatusColumn = selectedForm?.response_statuses?.length > 0;
  const columns = useMemo(() => {
    return [{
      className: 'Table__Cell__Title fs-exclude',
      label: t('forms:submission_header_name'),
      sort_key: 'name',
    }, {
      label: t('forms:submission_header_submitted_at'),
      sort_key: 'submitted_at',
    }, {
      label: t('forms:submission_header_community'),
    }, {
      label: t('forms:submission_header_function_group'),
    },
      showStatusColumn && {
        label: t('forms:submission_header_status'),
      }
    ].filter((value: any) => !!value);
  }, [t, showStatusColumn]);

  // when the user selects a date time question as predicate filter
  // we want to deactivate the scrolling on the question filter otherwise
  // the end date input calendar is hidden within the dropdown, see PD-8276
  const uiResponsivePredicates = useMemo(() => {
    const selectedId = questionPredicate?.value?.id;
    let scrollingQuestion = true;
    if (selectedId) {
      const question = questionsForFilter.find((q: any) => selectedId === q.id);
      if (question && question.type === EQuestionTypes.DATE_TIME) {
        scrollingQuestion = false;
      }
    }

    return predicates.map((pred: any) => {
      if (pred?.key === 'question') {
        return {
          ...pred,
          scrolling: scrollingQuestion,
          options: questionsForFilter
        };
      }
      return pred;
    });
  }, [questionPredicate, questionsForFilter, predicates]);

  const loggedModerator = useLoggedUserModerator(selectedForm);

  return (
    <Container.Content>
      <Bar>
        <FiltersToolbar
          predicates={uiResponsivePredicates}
          setPredicates={setPredicates}
          organisation={organisation}
          onSearch={setSearch}
          searchPlaceholder={t('forms:search_submissions')}
        />
      </Bar>
      <Overview>
        <OverviewHeader>
          <OverviewHeader.Title>{t('forms:form_submissions')}</OverviewHeader.Title>
          {
            loggedModerator ?
              <LoggedUserNotificationsSelect
                setEnabled={setLoggedUserNotificationsEnabled}
                formId={params.id}
                value={loggedModerator.enabled_notifications}
                organisationId={organisation?.id}
              /> :
              null
          }
          {
            submissions.length > 0 && (
              <FileExportDropdown
                label={t('forms:download_submissions')}
                downloadCSV={downloadCSV}
                downloadXLSX={downloadXLSX}
              />
            )
          }
        </OverviewHeader>
        <div className="ResponsesContainer">
          <OverviewContent>
            <AsyncTable
              // @ts-expect-error
              columns={columns}
              data={{
                useCursor: true,
                onFetch: fetchSubmissions,
                filter: {
                  formId: params.id,
                  search,
                  startDate,
                  endDate,
                  statusIds: statusPredicate?.value.value,
                  question: questionPredicate?.value,
                  networkIds: networkFilter?.value?.value,
                  functionIds: functionFilter?.value?.value,
                },
              }}
              selectedRow={params.submissionId}
              renderRow={({ item }) => {
                // @ts-expect-error
                const createdAt = moment(item.created_at);

                return [
                  <a
                    onClick={() => {
                      const path = submissionsPath ?
                        `${submissionsPath}/${item.id}` :
                        `/admin/forms/${params.id}/edit/submissions/${item.id}`;
                      history.push(path);
                    }}
                    role="button"
                  >
                    {item.user ? (
                      <ImageItem
                        imageSize={26}
                        // @ts-expect-error
                        name={item.user.full_name}
                        // @ts-expect-error
                        image={item.user.profile_img}
                      >
                        <small>{`#${item.index}`}</small>
                      </ImageItem>
                    ) : (
                      <>
                        {t('survey:survey_response_anonymous')}
                        <small>{`#${item.index}`}</small>
                      </>
                    )}
                  </a>,
                  <>
                    {createdAt.format('MMM D, YYYY')}
                    <br />
                    <small>{createdAt.format('HH:mm')}</small>
                  </>,
                  <>
                    {/* @ts-expect-error */}
                    {item.meta_data.networks[0]?.name}
                    {/* @ts-expect-error */}
                    {item.meta_data.networks.length > 1 && ` +${item.meta_data.networks.length - 1}`}
                  </>,
                  <>
                    {/* @ts-expect-error */}
                    {item.meta_data.functions[0]?.name}
                    {/* @ts-expect-error */}
                    {item.meta_data.functions.length > 1 && ` +${item.meta_data.functions.length - 1}`}
                  </>,
                  (
                    showStatusColumn &&
                    // @ts-expect-error
                    <SubmissionStatus status={item.response_status} />
                  )
                ].filter((value: any) => !!value);
              }}
              placeholder={(
                <Placeholder
                  image="/static/images/responses-placeholder.png"
                  title={t('forms:submissions_placeholder_title')}
                />
              )}
            />
          </OverviewContent>
          {
            params?.submissionId && submissions.length > 0 && (
              <Submission
                match={match}
                form={selectedForm}
                submission={submission}
                submissionMeta={submissionMeta}
                organisationId={organisation.id}
                fetchSubmission={fetchSubmission}
                updateSubmissionStatus={updateSubmissionStatus}
                deleteSubmission={deleteSubmission}
                submissionsPath={submissionsPath}
              />
            )
          }
        </div>
      </Overview>
    </Container.Content>
  );
};

export default Submissions;

function useSubmissionsFilters(
  data: any,
  selectedForm: any,
  questionsForFilter: any
) {
  return useState([{
    filter: EPredicateFilters[EPredicateFields.DATE],
    value: {
      attribute: EPredicateFields.DATE,
      comparison: EPredicateOperators.EQ,
      value: [null, null]
    },
    options: data[EPredicateFilters[EPredicateFields.DATE]?.optionsKey],
    scrolling: false
  }, {
    filter: EPredicateFilters[EPredicateFields.NETWORK],
    value: {
      attribute: EPredicateFields.NETWORK,
      comparison: EPredicateOperators.IN,
      value: []
    },
    options: data[EPredicateFilters[EPredicateFields.NETWORK]?.optionsKey],
  }, {
    filter: EPredicateFilters[EPredicateFields.FUNCTION],
    value: {
      attribute: EPredicateFields.FUNCTION,
      comparison: EPredicateOperators.IN,
      value: []
    },
    options: data[EPredicateFilters[EPredicateFields.FUNCTION]?.optionsKey],
  }, {
    key: 'status',
    filter: {
      icon: 'assignment',
      context: 'status',
      selector: PredicateSelectorMultiSelect,
      previewer: optionsPreviewer,
    },
    value: {
      comparison: EPredicateOperators.IN,
      value: [],
    },
    options: selectedForm?.response_statuses
  }, {
    key: 'question',
    filter: {
      icon: 'help',
      context: 'question',
      selector: QuestionSelector,
      previewer: QuestionPreviewer,
    },
    value: {
      id: null,
      answer: null,
    },
    options: questionsForFilter
  }]);
}
