import React, { useEffect } from 'react';
import { Route, useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Container } from '@common/components/container';
import Placeholder from '@common/components/placeholder';
import { QuestionDropdown } from '../../../survey/components/question-dropdown';
import { QuestionsList } from '../../../survey/components/questions-list';
import QuestionContainer from '../../../survey/containers/question';
import PhonePreview from '../../../learning/components/phone-preview';
import { EQuestionTypes } from '../../../survey/definitions';

import type { FormDetail } from '../../types';
import { QuestionScreen, Screen } from '@modules/learning/types/objects';

type FormQuestionsProps = {
  form: FormDetail;
  screen?: QuestionScreen;
  previewScreens?: Screen[];
  disabled?: boolean;
  match: any;
  createQuestion: any;
  deleteQuestion: any;
  duplicateQuestion: any;
  openQuestion: any;
  orderQuestions: any;
  saveOrderQuestions: any;
  addComponent?: any;
  deleteComponent?: any;
  updateComponent?: any;
  updateOrderComponents?: any;
};

const enabledComponents = [
  EQuestionTypes.CONTENT,
  EQuestionTypes.TEXT,
  // EQuestionTypes.MULTIPLE_CHOICE,
  EQuestionTypes.SLIDER,
  EQuestionTypes.DROPDOWN,
  EQuestionTypes.NUMBER,
  EQuestionTypes.BOOLEAN,
  EQuestionTypes.DATE_TIME,
  EQuestionTypes.FILE_UPLOAD,
  EQuestionTypes.CHECKLIST,
];

const enabledQuestionComponents = enabledComponents.filter((c) => c !== EQuestionTypes.CONTENT);

export const FormQuestions = ({
  form, screen, previewScreens, disabled = false, match, ...fns
}: FormQuestionsProps) => {
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (!match.params.screenId && form.screens && form.screens.length > 0) {
      history.replace(`${match.url}/${form.screens[0].id}${search}`);
    }
  }, [form.screens, search]);

  if ((form?.screens?.length === 0) || match.params.screenId === 'create') {
    return (
      <Placeholder
        image="/static/images/modules-placeholder.svg"
        title={t('forms:form_placeholder_title')}
        description={t('forms:form_placeholder_description')}
        action={(
          <QuestionDropdown
            // @ts-expect-error
            enabledComponents={enabledComponents}
            onSelect={fns.createQuestion}
          />
        )}
      />
    );
  }

  return (
    <Container.Content horizontal>
      <div style={{ flex: '0 0 350px', minWidth: 0 }}>
        <QuestionsList
          screenId={match.params.screenId}
          screens={form?.screens || []}
          duplicateQuestion={fns.duplicateQuestion}
          deleteQuestion={fns.deleteQuestion}
          openQuestion={fns.openQuestion}
          saveOrder={fns.saveOrderQuestions}
          addQuestion={fns.createQuestion}
          handleDrag={fns.orderQuestions}
          disabled={disabled}
          // @ts-expect-error
          enabledComponents={enabledComponents}
        />
      </div>
      {form && (
        <Route
          path={[
            '/admin/forms/:formId/edit/screens/:id',
            '/networks/:networkId/forms/:formId/edit/:id',
          ]}
          render={(props) => {
            return (
              <>
                <div style={{ flex: '1 1 auto', minWidth: 0 }}>
                  <QuestionContainer
                    enableDescription
                    disableComponents
                    key={props.match.params.id}
                    addComponent={fns.addComponent}
                    updateComponent={fns.updateComponent}
                    deleteComponent={fns.deleteComponent}
                    updateOrderComponent={fns.updateOrderComponents}
                    screen={screen}
                    // @ts-expect-error
                    enabledComponents={enabledQuestionComponents}
                    {...props}
                  />
                </div>
                <div style={{ flex: '0 0 334.75px', minWidth: 0 }}>
                  <PhonePreview
                    screenIDs={form?.screens.map((s) => s.id)}
                    navbarTitle={form.title}
                    showBackButton={false}
                    screens={previewScreens}
                    focusedScreenId={props.match.params.id}
                  />
                </div>
              </>
            );
          }}
        />
      )}
    </Container.Content>
  );
};
