import {
  useState, useCallback, useMemo
} from 'react';
import { useAppSelector } from '@common/hooks';
import {
  FormSubmissionComment, FormattedFormSubmittionComment,
  GetFormSubmissionsCommentsMeta
} from '@modules/forms/types';
import * as userSelector from '@modules/core/selectors/logged-user';
import Api from '@common/services/api';
import { ApiResponse } from '@common/services/api/types';

const baseSubmission = {
  actions: {
    can_delete_comments: true
  }
};

export const useComments = (
  organisationId: string,
  submissionId: string,
) => {
  const [comments, setComments] = useState<null | FormattedFormSubmittionComment[]>(null);

  const loggedUser = useAppSelector(userSelector.selected);

  const addComment = useCallback((comment: FormSubmissionComment) => {
    setComments((prevComments) => {
      return [
        ...(prevComments || []),
        {
          ...comment,
          user: {
            id: loggedUser.id,
            full_name: loggedUser.full_name,
            profile_img: loggedUser.profile_img,
            last_active: loggedUser.last_active
          }
        } as FormattedFormSubmittionComment
      ];
    });
  }, [setComments, loggedUser]);

  const onDelete = useCallback(async (commentId: string) => {
    await Api.delete(
      `/v1/organisations/${
        organisationId
      }/users/me/forms/submissions/${
        submissionId
      }/comments/${commentId}`
    );
    setComments((prevComments) => {
      if (!prevComments) return null;
      return prevComments.map((comment) => {
        if (comment.id === commentId) {
          return {
            ...comment,
            deleted_at: (new Date()).toISOString(),
            deleted_by: loggedUser.id
          };
        }
        return comment;
      });
    });
  }, [organisationId, submissionId, setComments, loggedUser]);

  const submissionConfig = useMemo(() => {
    return { ...baseSubmission, id: submissionId };
  }, [submissionId]);

  const getComments = useCallback(async () => {
    const response = await Api.get<
      ApiResponse<FormSubmissionComment[], GetFormSubmissionsCommentsMeta>
    >(
      `/v1/organisations/${
        organisationId
      }/users/me/forms/submissions/${
        submissionId
      }/comments?include=users`
    );
    const users = response?.meta?.related?.users || [];
    setComments([
      ...response.data.map((comment) => {
        return {
          ...comment,
          user: users.find((user) => user.id === comment.user_id)
        };
      }) as FormattedFormSubmittionComment[]
    ]);
    return response;
  }, [submissionId, organisationId, setComments]);

  return {
    comments,
    getComments,
    addComment,
    onDelete,
    submissionConfig
  };
};
