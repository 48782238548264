import moment from 'moment';

import { EPredicateOperators } from '@common/definitions';
import { Api } from '@common/services/api';
import { ListApiResponse } from '@common/types/util-types';
import { downloadFile } from '@common/utils/file';
import { BASE_URL } from '@common/constants';
import { getApiHeaders } from '@services/api/utils';
import { APIFormSubmission, APIFormSubmissionDetail, FormCounts } from './types';

export type FetchSubmissionsApiResponse = ListApiResponse<APIFormSubmission[], FormCounts>;

export type FetchSubmissionsFilters = {
  formId: string,
  sort_key?: 'created_at' | 'updated_at' | 'name';
  order?: 'asc' | 'desc';
  search?: string | null;
  startDate: string | null;
  endDate: string | null;
  statusIds: string[];
  networkIds: string[] | undefined;
  functionIds: string[] | undefined;
  question: { id: null, answer: null } | {
    id: string;
    answer: {
      attribute: 'answers.value';
      comparison: EPredicateOperators;
      value: unknown;
    };
  };
};

export const fetchSubmissions = async (
  organisationId: string,
  nextCursor: string | null,
  filter: FetchSubmissionsFilters,
  limit = 10
) => {
  const { question } = filter;

  const sortBy = filter?.sort_key && `${filter.sort_key}_${filter.order}`;

  const filterDate = !!(filter.startDate && filter.endDate);

  const query = Api.utils.toQuery({
    limit,
    cursor: nextCursor || true,
    sort_by: sortBy,
    q: filter.search,
  });

  const payload: Record<string, unknown> = {};
  if (filterDate) {
    payload.start_date = moment(filter.startDate).format('YYYY-MM-DD');
    payload.end_date = moment(filter.endDate).format('YYYY-MM-DD');
  }
  if (question?.id && question.answer && (Array.isArray(question.answer.value) ? question.answer.value.length > 0 : true)) {
    payload.question = filter.question;
  }
  if (filter.statusIds?.length > 0) payload.response_status_ids = filter.statusIds;
  if (Array.isArray(filter.networkIds) && filter.networkIds.length > 0) payload.networkIds = filter.networkIds;
  if (Array.isArray(filter.functionIds) && filter.functionIds.length > 0) payload.functionIds = filter.functionIds;

  return Api.post<FetchSubmissionsApiResponse>(
    `/v1/organisations/${organisationId}/forms/${filter.formId}/submissions/search?${query}`,
    payload,
  );
};

export const downloadSubmissions = async (
  organisationId: string,
  formId: string,
  fileName: string,
  extension: 'csv' | 'xlsx'
) => {
  const options = {
    method: 'POST',
    headers: await getApiHeaders(),
    credentials: 'include' as RequestCredentials,
    cache: 'no-cache' as RequestCache,
  };
  const path = `${BASE_URL}/v1/organisations/${organisationId}/forms/${formId}`;
  return downloadFile(
    `${path}/submissions/export` + (extension === 'csv' ? '' : '?output=xlsx'),
    `${fileName}.${extension}`,
    options
  );
};

type FetchSubmissionApiResponse = ListApiResponse<APIFormSubmissionDetail, FormCounts>;

export const fetchSubmission = (organisationId: string, formId: string, id: string) => {
  const url = `/v1/organisations/${organisationId}/forms/${formId}/submissions/${id}`;
  return Api.get<FetchSubmissionApiResponse>(url);
};

export const updateSubmissionStatus = (
  organisationId: string,
  formId: string,
  submissionId: string,
  status: any
) => {
  const url = `/v1/organisations/${organisationId}/forms/${formId}/` +
    `submissions/${submissionId}/response_status`;
  return Api.patch(url, {
    id: status.id,
  });
};

export const deleteSubmission = (
  organisationId: string,
  formId: string,
  submissionId: string
) => {
  const url = `/v1/organisations/${organisationId}/forms/${formId}/submissions/${submissionId}`;
  return Api.delete(url);
};
