import { useRef, useEffect } from 'react';

export default function useInputHeightAutogrow<
  InputT extends HTMLElement = HTMLTextAreaElement,
  ValueT = string
>(
  value: ValueT
) {
  const inputRef = useRef<InputT>(null);

  const input = inputRef.current;

  useEffect(() => {
    if (!input) return;

    input.style.height = '17px';
    input.style.height = `${input.scrollHeight}px`;
  }, [input, value]);

  return inputRef;
}
