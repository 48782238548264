import React, { memo } from 'react';

type Point = {
  id: string;
  color: string;
  element: JSX.Element | JSX.Element[] | string;
};

type PointsLineProps = {
  points: Point[];
};

const PointsLine = memo(({ points }: PointsLineProps) => {
  return (
    <div className="PointsLine">
      {
        points.map((point: Point, index: number) => {
          const isLastPoint = (index + 1) >= points.length;
          return (
            <>
              <div>
                <div
                  className="point"
                  style={{ backgroundColor: point.color }}
                />
                <div className="element">
                  { point.element }
                </div>
              </div>
              { isLastPoint ? null : <div className="line" /> }
            </>
          );
        })
      }
    </div>
  );
});

export default PointsLine;
