import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { AnswerPredicate } from '@common/types/objects';
import { ScreenQuestionItem } from '@modules/learning/types/objects';
import moment from 'moment';
import {
  optionsPreviewer,
  numberPreviewer,
} from '@common/components/predicates-filter';

const getQuestionPreviewerByType = (
  question: ScreenQuestionItem,
  answer: AnswerPredicate,
  t: TFunction
) => {
  if (!answer || !question || (Array.isArray(answer.value) && !answer.value.some((value) => !!value))) {
    return t('common:predicates_filter_comparison_eq', { value: t('common:predicates_filter_any') });
  }

  switch (question?.type) {
    case 'question_boolean':
      return t('common:predicates_filter_comparison_eq', {
        value: answer?.value === false
          ? question.parameters.label_false
          : question.parameters.label_true,
      });
    case 'question_datetime':
      // @ts-expect-error
      if (!answer.value[1]) {
        // @ts-expect-error
        return t('common:predicates_filter_comparison_from', { value: moment(answer.value[0]).format('D-M-YY') });
      }

      // @ts-expect-error
      if (!answer.value[0]) {
        // @ts-expect-error
        return t('common:predicates_filter_comparison_to', { value: moment(answer.value[1]).format('D-M-YY') });
      }

      return t('common:predicates_filter_comparison_between', {
        // @ts-expect-error
        value: moment(answer.value[0]).format('D-M-YY'),
        // @ts-expect-error
        otherValue: moment(answer.value[1]).format('D-M-YY'),
      });
    case 'question_dropdown':
    case 'question_multiple_choice':
      return optionsPreviewer(answer, {
        labelKey: 'value',
        valueKey: 'id',
      }, question.parameters.options);
    case 'question_number':
    case 'question_slider':
      return numberPreviewer(answer, {});
    default:
      return '';
  }
};

const QuestionPreviewer = (
  { id, answer }: { id: string, answer: AnswerPredicate },
  _: any,
  options: ScreenQuestionItem[]
) => {
  const { t } = useTranslation();

  const question = options.find((option) => option.id === id);

  if (!question) return `${t('common:predicates_filter_in')} ${t('common:predicates_filter_any')}`;

  const questionText = question.parameters.text.length > 15
    ? `${question.parameters.text.substring(0, 12)}...`
    : question.parameters.text;

  return (
    <span>
      {questionText}
      &nbsp;
      {getQuestionPreviewerByType(question, answer, t)}
    </span>
  );
};

export default QuestionPreviewer;
