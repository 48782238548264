import React, { memo, useCallback } from 'react';

import { IconTypes } from '@common/components/icon';
import { Button } from '../button';
import Dropdown from '../dropdown';
import { Icon } from '../icon';

type SelectToggleProps = {
  label: string;
  value: boolean;
  textOn: string;
  textOff: string;
  onChange: (value: boolean, field?: string) => void;
  name?: string;
  iconOn?: IconTypes;
  iconOff?: IconTypes;
  disabled?: boolean;
};

export const SelectToggle = memo(({
  name,
  value,
  iconOn,
  iconOff,
  label,
  textOn,
  textOff,
  onChange,
  disabled
}: SelectToggleProps) => {
  const setOn = useCallback(() => {
    if (!value) {
      onChange(true, name);
    }
  }, [onChange, value, name]);

  const setOff = useCallback(() => {
    if (value) {
      onChange(false, name);
    }
  }, [onChange, value, name]);

  return (
    <Dropdown
      alignRight
      className="SelectToggle"
      disabled={disabled}
      toggle={(
        <Button
          size="large"
          icon={value ? iconOn : iconOff}
          isLoading={false}
        >
          { label }:
          {' '}
          <div className="SelectToggle__Name">
            { value ? textOn : textOff }
          </div>
          <Icon type="arrow_drop_down" className="arrowDown" />
        </Button>
      )}
    >
      <Dropdown.Item onClick={setOn}>
        { textOn }
      </Dropdown.Item>
      <Dropdown.Item onClick={setOff}>
        { textOff }
      </Dropdown.Item>
    </Dropdown>
  );
});
