import { AlertService } from '@common/services/alert';
import React, { memo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../button';
import Dropdown from './dropdown';
import './file-export-dropdown.scss';

type FileExportDropdownProps = {
  label: string;
  downloadCSV: () => void;
  downloadXLSX: () => void;
  alignRight?: boolean;
};

type BaseFileExportDropdownProps = FileExportDropdownProps & {
  isLoading: boolean;
};

const BaseFileExportDropdown = memo(({
  isLoading,
  label,
  downloadCSV,
  downloadXLSX,
  alignRight = true
}: BaseFileExportDropdownProps) => {
  const { t } = useTranslation();
  return (
    <Dropdown
      alignRight={alignRight}
      className="FileExportDropdown"
      toggle={(
        <Button
          size="large"
          icon="download"
          isLoading={isLoading}
        >
          { label }
        </Button>
      )}
    >
      <Dropdown.Item onClick={downloadCSV}>
        {t('forms:csv_format')}
      </Dropdown.Item>
      <Dropdown.Item onClick={downloadXLSX}>
        {t('forms:excel_format')}
      </Dropdown.Item>
    </Dropdown>
  );
});

function useLoading(
  download: (...args: any[]) => any,
  setLoading: (loading: boolean) => void,
  t: (...args: any[]) => any
) {
  return useCallback(async () => {
    try {
      setLoading(true);
      await download();
    } catch (error) {
      console.log('debug error', error);
      AlertService.error(t('core:download_failed'));
    } finally {
      setLoading(false);
    }
  }, [download, setLoading, t]);
}

const FileExportDropdown = memo((props: FileExportDropdownProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const downloadCSV = useLoading(props.downloadCSV, setLoading, t);
  const downloadXLSX = useLoading(props.downloadXLSX, setLoading, t);

  return <BaseFileExportDropdown
    {...props}
    isLoading={loading}
    downloadCSV={downloadCSV}
    downloadXLSX={downloadXLSX}
  />;
});

export default FileExportDropdown;
